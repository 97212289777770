<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">
              영업일자
            </div>
            <ul class="content">
              <li class="item date">
                <input-date
                    type="lookup-condition"
                    format="YYYY-MM-DD"
                    v-model="nowDate"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              v-on:click.native="searchButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <ul class="lookup-button">
          <li class="print">
            <erp-button
                button-div="PRINT"
                @click.native="onPrintClick"
                :is-shortcut-button="true"
                >인쇄
            </erp-button>
          </li>
        </ul>
      </div>
    </div>
    <div class="content-body">
      <ReportView ref="reportViewComponent" :isPopup="false"/>
    </div>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import InputDate from "@/components/common/datetime/InputDate";
import ReportView from '@/components/common/report/ReportView';
import { DATE_FORMAT_YYYY_MM_DD } from "@/utils/date";
import moment from "moment";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {getDayOfWeekCaption} from "../../utils/date";
import {mapGetters} from "vuex";
import ErpButton from "@/components/button/ErpButton.vue";
// import {groupBy as _groupBy} from "lodash";
export default {
  name: "WeeklyMeetingReport",
  components:{
    InputDate,
    ReportView,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  data () {
    return{
      nowDate : null,
      nextDate: null,
      data:[]
    };
  },
  created() {
    this.nowDate = moment().format(DATE_FORMAT_YYYY_MM_DD);
  },
  mounted() {
    this.searchButtonClicked();
  },
  computed: {
    ...mapGetters(["username"]),
  },
  methods: {
   async searchButtonClicked(){
     this.data = await GolfErpAPI.fetchWeeklyMeetingReport({
       fromDate: this.nowDate,
       toDate:  await this.getDateOfNext(this.nowDate)
     });

     //대행사 예약수
     const agencyreservation = await this.agencyReservation();
     //오늘요일
     const day = await getDayOfWeekCaption(this.nowDate);
     //각 대행사 목록 타이틀
     const agencyTitle = await this.agencyTitleList();
     //각 요일별 매출액
     const salesMoney = await this.salesMoney();
     //대행사 날짜 리스트
     const agencyreservationdate = await this.agencyreservationdate();
      //예약값 7개 고정
     await this.fixedReservationItem();
     const reportData = {
       reportJson: {
         reportJson:{
           fromDate:this.nowDate.replaceAll("-","."),
           username: this.username,
           uniqueCode: this.$options.name,
           agencyTitle,
           salesMoney,
           agencyreservation,
           agencyreservationdate,
           day,
           data : this.data,
         }
       }
     };
     await this.$refs.reportViewComponent.postReport(
         reportData,
         `${this.$options.name}`,
     );
   },
    //대행사 예약수
    agencyReservation(){
     const arr = [];
       let data = this.data.agncyReservationStatusList.reduce((acc, curr) => {
         if(acc[curr.resveChannelCode]) {
           acc[curr.resveChannelCode].push(curr);
         } else {
           acc[curr.resveChannelCode] = [curr];
         }
         return acc;
       },{});
       Object.values(data).forEach((data2)=>{
         Object.values(data2).forEach((data3)=>{
           arr.push({
             "Cnt":data3.resveCnt,
             "name":data3.resveChannelName
           });
         });
       });
      let count = -1 ;
      let count2 = 0 ;
      const arr2 =  arr.reduce((acc, curr)=>{
       if(acc[count]?.name==curr.name){
         acc[count][`Cnt${count2+1}`] = curr.Cnt;
         count2++;
       }else{
         count2=0;
         count++;
         acc.push({
           "name":curr.name,
           "Cnt0":curr.Cnt
         });
       }
       return acc;
     },[]);
    return arr2;
    },

    //6일 후 날짜 가져오기
     getDateOfNext(date) {
      this.nextDate = moment(date);
      this.nextDate.add("day", 6).calendar();
      return this.nextDate.format("YYYY-MM-DD");
    },

    //각 요일별 매출액
    salesMoney(){
      let count = -1 ;
      let count2 = 0 ;
      const list  =  this.data.salesStatusByDateList.reduce((acc, curr) => {
       if(acc[count]?.date==curr.bsnDate&&acc[count]?.date){
         acc[count][`totSalesAmt${count2}`] = curr.totSalesAmt;
         count2++;
       } else {
         count2=0;
         count++;
         acc.push({
           "date":curr.bsnDate,
           "totSalesAmt":curr.totSalesAmt
         });
     }
       return acc;
     },[]);

      return list ;
    },

    //대행사이름만 빼기
    agencyTitleList(){
     let list =  this.data.agncyReservationStatusList.map(data=>{
        return data.resveChannelName;
      });
      let set = new Set(list);
      const set2 = [...set];
      const Arr = set2.map((ele)=> { return {"title" : ele};});
      return Arr;
    },

    //대행사 날짜 리스트
    agencyreservationdate(){
     const list = this.data.statusOfUtilizationByReservationChannelList.map((data,index)=>{
       let bsnDate = data.bsnDate.split("-");
       return {
         [`date${index}`]:bsnDate[1]+"월 " + bsnDate[2]+"일",
         [`Code${index}`]:data.bsnCode,
         [`Name${index}`]:data.dwName,
       };
     });
     const ob = {};
      Object.values(list).forEach((data)=>{
       Object.keys(data).forEach((data2)=>{
       ob[data2] = data[data2];
        });
      });
      return [ob];
    },

    //예약항목 7개 고정정
   fixedReservationItem(){
     const list = this.data.reservationTeamStatusList;
     let listLength = this.data.reservationTeamStatusList.length;
     if(listLength<7){
       while(listLength<7){
         list.push({});
         listLength++;
       }
     }

    },

    onPrintClick() {
      this.$refs.reportViewComponent.printReport();
    }
  },

};
</script>

<style scoped>

</style>